<template>
  <div id="codesales" class="grey lighten-3 fill-height">
    <v-container>
      <!-- title -->
      <v-row justify="center">
        <h1 class="text-title my-9">
          <v-icon class="text-h3 mr-2" color="green darken-1">
            mdi-table-star
          </v-icon>
          Discount Code Sales
        </h1>
      </v-row>

      <!-- Tests Cards -->
      <v-row justify="center" class="my-4" v-if="codeData != null">
        <v-col
          cols="12"
          sm="12"
          md="3"
          v-for="(code, i) in codeData.discountCodes"
          :key="i"
        >
          <v-card class="card-gradient" :loading="superLoading">
            <v-list-item two-line>
              <v-list-item-content class="white--text">
                <v-list-item-title>
                  Sales: {{ codeData.codeSales[code] }}
                </v-list-item-title>
                <v-list-item-subtitle class="white--text">
                  {{ code }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "CodeSales",
  metaInfo: {
    title: "CodeSales",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Code Sales Page description lorem ipsum dolor sit amet.",
      },
    ],
  },
  data: () => ({
    error: "",
    superLoading: true,
    codeData: null,
  }),
  methods: {
    setSuperLoading(value) {
      this.superLoading = value;
    },
    getCodeSales() {
      this.superLoading = true;
      this.$store
        .dispatch("getCodeSales")
        .then((res) => (this.codeData = res))
        .catch((error) => (this.error = error))
        .finally(() => (this.superLoading = false));
    },
  },
  mounted() {
    this.getCodeSales();
  },
};
</script>

<style scoped>
.card-gradient {
  background: #141e30; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>